@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri&display=swap');


// $logo-font: 'Noto Sans JP', sans-serif
$logo-font: 'Amiri', serif;
$content-font: 'Karla', sans-serif;

// sizes
$text-large: 1.45rem;
$text-medium: 1.25rem;
$font-size: 1.05rem;
$line-height: 1.8;
$spacer: 2rem;
$spacer-small: 1rem;
$spacer-0: 0;

// colours
$white: #FFF;
$grey: #f9f9f9;
$black: #282c34;
$text-light: #999;
$text-dark: #444444;
$text-accent: #46a79ee3;

// other possible colours
// #4683a7e3
// #46a79ee3
// #6c83d8
// #dc3a94b5

