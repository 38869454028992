.home {
    flex: 1;
    padding: $spacer;
    padding-top: calc(#{$spacer} * 2);

    @include ipad {
        padding: calc(#{$spacer} * 2);
        padding-top: calc(#{$spacer} * 3); }

    @include desktop-up {
        padding: calc(#{$spacer} * 2); } }


.home__projects__item {

    line-height: 1;
    display: flex;
    align-items: center;
    font-size: 9vw;
    margin-bottom: $spacer;

    @include tablet-up {
        font-size: 6.7vw; }

    @include desktop-up {
        margin-bottom: 0; }

    &:last-child {
        margin-bottom: 0; } }


.home__projects__item__date {
    display: inline-block;
    margin-left: .5em;
    font-size: .30em;
    color: $text-light;
    display: none; }


.home__projects__item__link {
    line-height: 1;
    color: $black;
    font-family: $content-font;
    text-transform: capitalize;
    font-weight: bold;
    position: relative;
    letter-spacing: -3px;
    transition: all 200ms ease-out;

    &:before {
        content: attr(data-text);
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
        opacity: 0;
        transition: opacity 100ms ease-out; }


    &:hover {
        transform: translateY(-5px);
        color: transparent;

        &:before {
            opacity: 1; } } }



