.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 999999999999; }


.loading__logo {
    font-family: $logo-font;
    font-size: 5rem;
    animation: zoom 1s ease-in-out infinite alternate; }
