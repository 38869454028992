@keyframes fill {
    0% {
        transform: translateX(-300px); }
    100% {
        transform: translateX(300px); } }



@keyframes zoom {
    0% {
        transform: scale(.6); }
    100% {
        transform: translateX(1.4); } }
