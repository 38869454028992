.project {

    padding: calc(#{$spacer} * 2) $spacer;
    // padding: $spacer

    @include ipad {
        padding: calc(#{$spacer} * 4) calc(#{$spacer} * 2); }

    @include desktop-up {
        padding: calc(#{$spacer} * 4); } }


.project__header {
    font-family: $content-font;
    position: relative;
    margin-bottom: calc(#{$spacer} * 1.25);

    @include desktop-up {
        margin-bottom: calc(#{$spacer} * 3); } }


.project__header__title {
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
    letter-spacing: -2px;
    color: $black;
    font-size: calc(5.5vw + 20px);
    margin-bottom: $spacer;

    @include desktop-up {
        letter-spacing: -5px; } }


.project__header__description {

    line-height: 1.8;
    font-size: 16px;
    margin-bottom: $spacer;
    max-width: 640px;
    color: $text-light;

    @include ipad {
        font-size: 18px; }

    @include desktop-up {
        font-size: 24px; } }


.project__photos {

    @include desktop-up {

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }


.project__photos__item {

    margin-bottom: $spacer;

    &:last-child {
        margin-bottom: 0; }

    @include desktop-up {
        flex-basis: 31%;
        margin-bottom: calc(#{$spacer} * 2); }

    figure img {
        width: 100%; } }


.project__header__date {
    display: none;
    font-size: 5vw;
    color: $text-light;

    @include ipad {
        font-size: 3.5vw; }

    @include desktop-up {
        font-size: 1.2vw; } }


.project__header__meta {
    display: flex;
    justify-content: space-between;

    @include desktop-up {
        display: block;
        position: absolute;
        top: 10%;
        right: 0;
        text-align: right; } }


.project__header__meta  li {

    @include ipad {
        font-size: 1.125rem; }

    @include desktop-up {
        font-size: 1.1rem;
        margin-bottom: 24px;
        color: $black; }

    a {
        text-decoration: underline; } }
