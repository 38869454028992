.kc__header {
    padding: calc( #{$spacer} * 2) $spacer;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include ipad {
        padding: calc(#{$spacer} * 2);
        padding-bottom: 0; }

    @include desktop-up {
        padding: calc(#{$spacer} * 2); } }


.kc__header__logo a {
    font-family: $logo-font;
    color: $text-light;
    font-size: 1.3rem;
    transition: all 200ms ease-out;

    &:hover {
        color: $black; } }


.kc__header__link {
    text-transform: uppercase;
    text-decoration: underline;
    color: $text-light;
    transition: all 200ms ease-out;

    &:hover {
        color: $black; }

    svg {
        width: 1.3rem;
        height: 1.3rem; } }
