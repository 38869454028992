@import './utils/index';
@import './pages/index';

html, body, #root, main {
    height: 100%;
    position: relative; }


.kc {
    display: flex;
    flex-direction: column; }

