.about {
    font-family: $content-font;
    padding: calc(#{$spacer} * 3) $spacer;

    @include ipad {
        padding: calc(#{$spacer} * 3) calc(#{$spacer} * 2); }

    @include desktop-up {
        padding: calc(#{$spacer} * 2); } }


.about .container {
    max-width: 800px;
    margin: 0 auto; }


.about__portrait {

    margin-bottom: $spacer;


    @include ipad {
        margin-bottom: calc(#{$spacer} * 2); }

    @include desktop-up {
        margin-bottom: calc(#{$spacer} * 2); } }


.about__portrait img {
    max-width: 100%;
    width: 100%;
    height: auto;
    object-fit: cover; }


.about__content__heading {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 1rem; }


.about__content {
    margin-bottom: $spacer; }


.about__content h3 {
    font-size: 3rem;
    line-height: 1;
    font-weight: bold;
    margin-bottom: calc(#{$spacer} * 2); }


.about__content p {

    margin-bottom: $spacer;
    line-height: 1.8;
    font-size: 1rem;

    @include ipad {
        font-size: 1.125rem; }

    @include desktop-up {
        font-size: 1.5rem; }

    &:last-child {
        margin-bottom: 0; } }


.about__contact {
    padding: $spacer 0; }


.about__contact__email {
    left: calc(#{$spacer} * 2); }


.about__contact__insta {
    right: calc(#{$spacer} * 2); }


.contact-link {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    transition: all 200ms ease-out;
    margin-bottom: $spacer;
    color: $text-light;
    font-weight: bold;

    &:last-child {
        margin-bottom: 0; }

    &:hover {
        color: $black; }

    @include ipad {
        font-size: 1.25rem; }

    @include desktop-up {
        font-size: 1.5rem;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 16%, #FFCC70 38%);
        font-weight: bold; }

    @include retina {
        position: fixed;
        bottom: $spacer;
        margin-bottom: 0;
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 56%, #FFCC70 100%); } }

.contact-link svg {

    width: 1.1rem;
    height: 1.1rem;
    margin-right: .35rem;

    @include desktop-up {
        display: none;
        width: 1.3vw;
        height: 1.3vw; }

    @include retina {
        width: 1vw;
        height: 1vw;
        margin-right: .35rem; } }
