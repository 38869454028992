@mixin iphoneSE {
    @media screen and (device-aspect-ratio: 40/71) {
        @content; } }

@mixin phone {
    @media only screen and (min-width: 375px) and (max-width: 667px) {
        @content; } }

@mixin iphone678-landscape {
    @media only screen and (min-width: 375px) and (max-width: 667px) and (orientation: landscape) {
        @content; } }

@mixin ipad {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
       @content; } }

@mixin ipad-landscape {
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
       @content; } }

@mixin tablet-portrait {
    @media (min-width: 600px) and (max-width: 1199px) and (orientation: portrait) {
       @content; } }

@mixin tablet-landscape {
    @media (min-width: 600px) and (max-width: 1199px) and (orientation: landscape) {
       @content; } }

@mixin tablet-up {
    @media (min-width: 600px) and (orientation: landscape) {
       @content; } }

@mixin desktop-up {
    @media only screen and (min-width: 1200px) {
       @content; } }

@mixin retina {
    @media (min-width: 1800px) {
       @content; } }
