.kc__not-found {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;

    padding: calc(#{$spacer});

    @include ipad {
        padding: calc(#{$spacer} * 2);
        padding-top: calc(#{$spacer} * 3); }

    @include desktop-up {
        padding: calc(#{$spacer} * 2); } }


.kc__not-found > .container {
    max-width: 700px;
    margin: 0 auto; }


.kc__not-found__cover {

    img {
        width: 100%; } }


.kc__not-found__credits {
    margin-top: $spacer;
    text-align: center;
    color: $text-light;
    font-size: .9rem; }
