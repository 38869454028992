.kc__menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    color: white;
    z-index: 9999999;

    &.show {
        display: block; } }


// header
.kc__menu__header {
    padding: calc( #{$spacer} * 2);
    display: flex;
    align-items: center;
    justify-content: space-between; }


.kc__menu .container {
    padding: calc( #{$spacer} * 2); }


.kc__menu__logo {
    font-family: $logo-font;
    font-size: 3rem; }


// nav
.kc__menu__nav {
    margin-bottom: calc(#{$spacer} * 4); }


.kc__menu__nav__item {
    margin-bottom: calc(#{$spacer} * 2); }


.kc__menu__nav__link {
    font-size: calc(#{$text-large} * 3);
    font-weight: bold;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 5px;
        background-color: currentColor;
        transition: transform 150ms ease-out, background-color 150ms ease-out; } }


// contact
.kc__menu__contact__item {
    margin-bottom: $spacer; }


.kc__menu__contact__link {
    display: inline-flex;
    align-items: center;
    justify-content: space-between; }


.kc__menu__contact__link span {
    font-size: 1.45rem;
    text-decoration: underline; }


.kc__menu__contact__link svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem; }


// footer
.kc__menu__footer {
    position: fixed;
    bottom: calc(#{$spacer} * 2);

    a {
        text-decoration: underline; } }
