.error {
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        max-width: 37em;
        width: 100%; } }


.error__header {
    margin-bottom: 1.5rem; }


.error__header__title {
    font-size: 2.5em;
    text-transform: capitalize;
    font-weight: 700; }


.error__text p {
    font-size: 1.2em;
    max-width: 80%;
    line-height: 1.6;
    margin-bottom: 1rem;
    font-family: $content-font; }


.error__text p > a {
    font-weight: bold;
    color: $text-accent;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        transform: translateY(-3px);
        display: block;
        width: 100%;
        height: 3px;
        background-color: currentColor;
        transition: transform 150ms ease-in-out; }

    &:hover {

        &:after {
            transform: translateY(0); } } }
